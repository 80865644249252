<template>
  <Box customClass="is-shadowless">
    <block-title customClass="mt-0 mb-4 mr-5 ml-4" title="" />
    <calendar is-expanded :attributes='attr' title-position="left" />
  </Box>
</template>

<script>
import { Calendar } from 'v-calendar';
import { reactive, toRefs, defineAsyncComponent } from 'vue'

export default {
  components: {
    Calendar,
    Box: defineAsyncComponent(() => import('@/components/ds/Box')),
    BlockTitle: defineAsyncComponent(() => import('@/components/ds/BlockTitle')),
  },
  setup () {
    const state = reactive({
      attr: [
        {
          // Attribute type definitions
          highlight:  {
            color: 'red',
            fillMode: 'light',
          },  // Boolean, String, Object
          dates: new Date(),
          excludeDates: null,
          order: 0
        }
      ]
    })

    return {
      ...toRefs(state),
    }
  }
}
</script>

<style lang="scss">
  .vc-container {
    background: transparent;
  }
  .vc-header {
    padding-bottom: 10px;
  }
</style>
